import {
  Avatar,
  ChatContainer,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  Search,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  collection,
  doc,
  doc as firestoreDoc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";

// English.
import { Icon } from "@iconify/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import en from "javascript-time-ago/locale/en";
import { APP_OFFICIAL_USER_UID } from "libs/admin";
import { getCdnUrl } from "libs/images";
import { getLocationFromAddress } from "libs/profile";
import { trimSpaces } from "libs/stringUtils";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

const firestore = getFirestore();

const functions = getFunctions();
const sendChatMessage = httpsCallable(functions, "sendChatMessage");

const ANNIE_UID = "tNC3maTt5qUWzh8yN7TY6ztF3953";

const Inbox = ({ user, currentProfile }) => {
  const navigate = useNavigate();
  const [threads, setThreads] = useState([]);
  const [thread, setThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [threadsLoading, setThreadsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [composedMessage, setComposedMessage] = useState(null);
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get("search") ?? ""
  );
  const [debouncedSeachQuery, setDebouncedSearchQuery] = useState(
    searchParams.get("search") ?? ""
  );
  const [messageFilterType, setMessageFilterType] = useState("all");
  const [isAiEnabled, setIsAiEnabled] = useState(false);

  const debouncedSearch = useRef(
    debounce(async (query) => {
      setDebouncedSearchQuery(query);
    }, 300)
  ).current;

  const handleSearchChange = (text) => {
    setSearchQuery(text);
    debouncedSearch(text);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    async function search() {
      if (messageFilterType === "all") {
        if (debouncedSeachQuery && trimSpaces(debouncedSeachQuery) !== "") {
          const username = trimSpaces(debouncedSeachQuery);
          const userId = await getUserIdByUsername(username);
          if (userId) {
            const req = onSnapshot(
              query(
                collection(firestore, "chats"),
                where("user_ids", "array-contains", userId),
                orderBy("updated_at", "desc"),
                limit(searchParams.get("limit") ?? 100)
              ),
              (querySnapshot) => {
                console.log("Search by username");
                updateThreads(querySnapshot);
              }
            );

            return req;
          }
          setThreads([]);
        }

        const req = onSnapshot(
          query(
            collection(firestore, "chats"),
            orderBy("updated_at", "desc"),
            limit(searchParams.get("limit") ?? 100)
          ),
          (querySnapshot) => {
            console.log("This snapshot is still alive");
            updateThreads(querySnapshot, messageFilterType);
          }
        );

        return req;
      } else if (messageFilterType === "annie") {
        const req = onSnapshot(
          query(
            collection(firestore, "chats"),
            where("user_ids", "array-contains", ANNIE_UID),
            orderBy("updated_at", "desc"),
            limit(searchParams.get("limit") ?? 100)
          ),
          (querySnapshot) => {
            updateThreads(querySnapshot, messageFilterType);
          }
        );

        return req;
      } else if (messageFilterType === "no-annie") {
        const req = onSnapshot(
          query(
            collection(firestore, "chats"),
            orderBy("updated_at", "desc"),
            limit(searchParams.get("limit") ?? 150)
          ),
          (querySnapshot) => {
            updateThreads(querySnapshot, messageFilterType);
          }
        );

        return req;
      }
    }
    const unsub = search();
    return async () => {
      const unsubFunc = await unsub;
      unsubFunc();
    };
  }, [debouncedSeachQuery, searchParams, messageFilterType]);

  useEffect(() => {
    fetchMessages();
  }, [thread]);

  useEffect(() => {
    if (thread) {
      setIsAiEnabled(!thread.human_mode);
    }
  }, [thread]);

  async function processThreadSnapshot(documentSnapshot) {
    const data = documentSnapshot.data();
    const userData = await fetchThreadUser(data);

    if (data.last_message) {
      let threadItem = {
        id: documentSnapshot.id,
        name: documentSnapshot.id,
        avatar1: "/logo192.png",
        avatar2: "/logo192.png",
        userIds: data.user_ids,
        lastActivityTime: timeAgo.format(data.updated_at.toDate()),
        updatedAt: data.updated_at,
        info: data.last_message,
        unread: 0,
        human_mode: data.human_mode || false,
      };
      threadItem = {
        ...threadItem,
        name: `${userData?.[0] ? userData?.[0]?.name : "Someone"} and ${
          userData?.[1] ? userData?.[1]?.name : "Someone"
        }`,
        avatar1: getCdnUrl(userData?.[0]?.avatar, 80, 80) ?? "/logo192.png",
        avatar2: getCdnUrl(userData?.[1]?.avatar, 80, 80) ?? "/logo192.png",
      };
      if (userData?.[0] && userData?.[1]) {
        threadItem = {
          ...threadItem,
          users: userData,
        };
      }
      return threadItem;
    }

    return null;
  }

  async function processAllThreadSnapshots(snapshot, messageFilterType) {
    if (snapshot) {
      const promises = snapshot.docs.map(processThreadSnapshot);
      const results = await Promise.all(promises);

      // Filter out null values if some documentSnapshots did not have last_message
      let threadsData = results.filter((threadItem) => threadItem !== null);

      if (messageFilterType === "no-annie") {
        threadsData = threadsData.filter(
          (threadItem) =>
            !threadItem.userIds.includes(ANNIE_UID) &&
            !threadItem.userIds.includes(APP_OFFICIAL_USER_UID)
        );
      } else if (messageFilterType === "annie") {
        threadsData = threadsData.filter((threadItem) => {
          console.log(threadItem);
          return (
            threadItem.info?.indexOf("welcome to our WoofyClub pack") === -1
          );
        });
      }

      // Sort threadsData based on updatedAt
      threadsData.sort((a, b) => b.updatedAt - a.updatedAt);

      return threadsData;
    }

    return [];
  }

  const updateThreads = async (snapshot, excludingAnnie = false) => {
    processAllThreadSnapshots(snapshot, excludingAnnie)
      .then((threadsData) => {
        setThreads(threadsData);
        setThreadsLoading(false);
      })
      .catch((error) => {
        console.error("Error processing document snapshots:", error);
        setThreadsLoading(false);
      });
  };

  const fetchThreadUser = async (threadToFetch) => {
    if (!threadToFetch.user_ids) {
      return null;
    }
    let firstUser = null;
    let secondUser = null;

    try {
      const firstUserSnap = await getDoc(
        doc(firestore, "users", threadToFetch.user_ids[0])
      );
      if (firstUserSnap.exists()) {
        const data = firstUserSnap.data();
        if (data) {
          firstUser = {
            username: data.username,
            name: data.name ?? data.first_name,
            avatar: data.profile_pic_url,
            id: firstUserSnap.id,
            area: `${getLocationFromAddress(data.address)}`,
          };
        }
      }
      const secondUserSnap = await getDoc(
        doc(firestore, "users", threadToFetch.user_ids[1])
      );
      if (secondUserSnap.exists()) {
        const data = secondUserSnap.data();
        if (data) {
          secondUser = {
            username: data.username,
            name: data.name ?? data.first_name,
            avatar: data.profile_pic_url,
            id: secondUserSnap.id,
            area: `${getLocationFromAddress(data.address)}`,
          };
        }
      }

      // console.log(firstUser, secondUser);
      return [firstUser, secondUser];
    } catch (err) {
      console.log("InboxThreadScreen.js: Error fetching thread", err);
    }
  };

  const sendMessage = async () => {
    await sendChatMessage({
      thread_id: thread.id,
      message: {
        text: composedMessage,
      },
      platform: "web",
    });
  };

  const fetchMessages = () => {
    if (!thread) {
      return;
    }
    console.log(thread.id);
    setComposedMessage(null);
    setMessagesLoading(true);
    return onSnapshot(
      query(
        collection(firestore, `chats/${thread.id}/messages`),
        orderBy("created_at", "desc"),
        limit(50)
      ),
      (querySnapshot) => {
        if (querySnapshot) {
          const newMessages = [];
          querySnapshot?.forEach((snapshot) => {
            if (snapshot.exists) {
              const data = snapshot.data();
              newMessages.push({
                _id: snapshot.id,
                text: `[${moment(data.created_at.toDate()).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}] ${data.text}`,
                createdAtTimestamp: data.created_at,
                createdAt: data.created_at.toDate(),
                user: {
                  _id: data.sender_id,
                  name: data.sender_name,
                  avatar: getCdnUrl(data.sender_profile_pic_url, 80, 80),
                },
                sent: true,
              });
            }
          });
          setMessages(
            newMessages.sort((a, b) => {
              if (a.createdAtTimestamp < b.createdAtTimestamp) {
                return -1;
              } else if (a.createdAtTimestamp > b.createdAtTimestamp) {
                return 1;
              } else {
                return 0;
              }
            })
          );
          setMessagesLoading(false);
        }
      },
      (e) => {
        console.log("InboxThreadScreen.js: Error fetching messages", e);
        setMessagesLoading(false);
      }
    );
  };

  const toggleAiAssistant = async () => {
    if (!thread) return;

    try {
      const threadRef = firestoreDoc(firestore, `chats/${thread.id}`);
      const newAiEnabled = !isAiEnabled;

      await updateDoc(threadRef, {
        human_mode: !newAiEnabled,
      });

      setIsAiEnabled(newAiEnabled);
    } catch (error) {
      console.error("Error toggling AI assistant:", error);
    }
  };

  return (
    <div style={{ position: "relative", height: "80vh" }}>
      <MainContainer>
        <Sidebar position="left">
          <div key={`inline-radio`} className="mt-3 mb-2">
            <Form.Check
              inline
              label="All"
              name="group1"
              type={"radio"}
              id={`inline-radio-all`}
              checked={messageFilterType === "all"}
              onChange={() => setMessageFilterType("all")}
            />
            <Form.Check
              inline
              label="No Annie"
              name="group1"
              type={"radio"}
              id={`inline-radio-no-annie`}
              checked={messageFilterType === "no-annie"}
              onChange={() => setMessageFilterType("no-annie")}
            />
            <Form.Check
              inline
              label="Only Annie"
              name="group1"
              type={"radio"}
              id={`inline-radio-annie`}
              checked={messageFilterType === "annie"}
              onChange={() => setMessageFilterType("annie")}
            />
          </div>
          {messageFilterType === "all" && (
            <Search
              placeholder="Search by username..."
              onChange={(v) => handleSearchChange(v)}
              onClearClick={(v) => handleSearchChange("")}
              value={searchQuery ?? ""}
            />
          )}
          <ConversationList className="thread-list" loading={threadsLoading}>
            {threads.map((item) => (
              <Conversation
                key={item.id}
                active={item.id === thread?.id}
                onClick={() => setThread(item)}
                info={item.info}
                name={item.name}
                lastActivityTime={item.lastActivityTime}
              >
                <Avatar name={item.name} src={item.avatar1} />
              </Conversation>
            ))}
          </ConversationList>
        </Sidebar>
        <ChatContainer>
          {thread && (
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar name={thread.name} src={thread.avatar1} />
              <ConversationHeader.Content
                info={thread.lastActivityTime}
                userName={thread.name}
              />
              <ConversationHeader.Actions>
                <button
                  onClick={toggleAiAssistant}
                  className="btn btn-outline-primary btn-sm me-3"
                >
                  <Icon
                    icon={isAiEnabled ? "mdi:robot" : "mdi:robot-off"}
                    style={{ fontSize: 16, marginRight: 4 }}
                  />
                  {isAiEnabled ? "Disable AI Assistant" : "Enable AI Assistant"}
                </button>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/profile/${thread.users?.[0].username}`}
                >
                  <Icon icon="ri-home-2line" style={{ fontSize: 16 }} />
                  <span className="me-3">
                    {thread.users?.[0].name} ({thread.users?.[0].area})
                  </span>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/profile/${thread.users?.[1].username}`}
                >
                  <Icon icon="ri-home-2line" style={{ fontSize: 16 }} />
                  <span className="me-3">
                    {thread.users?.[1].name} ({thread.users?.[1].area})
                  </span>
                </a>
              </ConversationHeader.Actions>
            </ConversationHeader>
          )}
          <MessageList loading={messagesLoading}>
            {messages.map((message) => (
              <Message
                className={
                  thread?.userIds?.[0] === message.user._id
                    ? "text-start"
                    : "text-end"
                }
                key={message._id}
                model={{
                  direction:
                    thread?.userIds?.[0] === message.user._id
                      ? "incoming"
                      : "outgoing",
                  message: message.text,
                  sentTime: message.createdAt,
                  sender: message.user.name,
                }}
              >
                <Avatar
                  onClick={() => {
                    window.open(
                      thread.users?.[0].id === message.user._id
                        ? `/profile/${thread.users?.[0].username}`
                        : `/profile/${thread.users?.[1].username}`
                    );
                  }}
                  name={message.user.name}
                  src={message.user.avatar}
                />
                <Message.Footer
                  sender={message.user.name}
                  sentTime={message.createdAt.toLocaleDateString()}
                />
              </Message>
            ))}
          </MessageList>
          {(user.uid === thread?.users?.[0].id ||
            user.uid === thread?.users?.[1].id) && (
            <MessageInput
              className="text-start"
              attachDisabled={true}
              onSend={sendMessage}
              onChange={(_, text) => {
                setComposedMessage(text);
              }}
            />
          )}
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

const getUserIdByUsername = async (username) => {
  const userSnap = await getDocs(
    query(
      collection(firestore, "users"),
      where("username", "==", username),
      limit(1)
    )
  );
  let userId = null;
  userSnap?.forEach((snapshot) => {
    if (snapshot.exists()) {
      userId = snapshot.id;
    }
  });
  return userId;
};

export default Inbox;
